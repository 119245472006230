import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";

import ScrollToTop from "../layout/ScrollToTop";
import Title from "../layout/Title";
import { RenderLink } from "../../utils";

const PublicationsPage = () => {
  const JournalsFragment = () => (
    <>
      <Typography variant="h4" fontWeight="bold" mt={10} mb={5}>
        Journals
      </Typography>
      <Typography variant="h5" fontWeight="bold" mb={3}>
        2024
      </Typography>
      <Typography variant="h6" mb={3}>
        Sascha Welten, Marius de Arruda Botelho Herr, Lars Hempel, David Hieber, Peter Placzek, Michael Graf, 
        Sven Weber, Laurenz Neumann, Maximilian Jugl, Liam Tirpitz, Karl Kindermann, Sandra Geisler, 
        Luiz Olavo Bonino da Silva Santos, Stefan Decker, Nico Pfeifer, Oliver Kohlbacher, Toralf Kirsten;  
        A study on interoperability between two Personal Health Train infrastructures in leukodystrophy data analysis. 
        In <em>Sci Data</em> 11, 663 (2024); (DOI:{" "}
        <RenderLink
          href="https://doi.org/10.1038/s41597-024-03450-6"
          text="10.1038/s41597-024-03450-6"
        />
        )
      </Typography>
      <Typography variant="h6" mb={3}>
        Sascha Welten, Sven Weber, Adrian Holt, Oya Beyan, Stefan Decker; 
        Will it run?—A proof of concept for smoke testing decentralized
        data analytics experiments. <em>Frontiers in Medicine</em> 2024; (DOI:{" "}
        <RenderLink
          href="https://doi.org/10.3389/fmed.2023.1305415"
          text="10.3389/fmed.2023.1305415"
        />
        )
      </Typography>
      <Typography variant="h5" fontWeight="bold" mb={3}>
        2023
      </Typography>
      <Typography variant="h6" mb={3}>
        Yongli Mou, Feifei Li, Sven Weber, Sabith Haneef, Hans Meine, Liliana Caldeira, 
        Mehrshad Jaberansary, Sascha Welten, Yeliz Yediel Ucer, Guido Prause, 
        Stefan Decker, Oya Beyan, Toralf Kirsten; 
        Distributed Privacy-Preserving Data Analysis in NFDI4Health With the Personal Health Train. 
        In Proceedings of <em>the Conference on Research Data Infrastructure</em> 2023 Sep 7 (Vol. 1); (DOI:{" "}
        <RenderLink
          href="https://www.tib-op.org/ojs/index.php/CoRDI/article/view/282"
          text="10.52825/cordi.v1i.282"
        />
        )
      </Typography>
      <Typography variant="h6" mb={3}>
        Jiahui Geng, Yongli Mou, Qing Li, Feifei Li, Oya Beyan, Stefan Decker,
        Chunming Rong; Improved Gradient Inversion Attacks and Defenses in
        Federated Learning. <em>IEEE Transactions on Big Data</em> 2023; (DOI:{" "}
        <RenderLink
          href="https://doi.ieeecomputersociety.org/10.1109/TBDATA.2023.3239116"
          text="10.1109/TBDATA.2023.3239116"
        />
        )
      </Typography>
      <Typography variant="h6" mb={7}>
        Kais Tahar, Tamara Martin, Yongli Mou, Raphael Verbuecheln, Holm
        Graessner, Dagmar Krefting; Rare Diseases in Hospital Information
        Systems-An Interoperable Methodology for Distributed Data Quality
        Assessments. <em>Methods of Information in Medicine AAM</em> 2023; (DOI:{" "}
        <RenderLink
          href="http://dx.doi.org/10.1055/a-2006-1018"
          text="10.1055/a-2006-1018"
        />
        )
      </Typography>
      <Typography variant="h5" fontWeight="bold" mb={3}>
        2022
      </Typography>
      <Typography variant="h6" mb={3}>
        Geng, Jiahui, Ali Akbar Rehman, Yongli Mou, Stefan Decker, and Chunming
        Rong; Blockchain-based Cross-organizational Workflow Platform.
        <em>
          2022 IEEE International Conference on Cloud Computing Technology and
          Science (CloudCom), Bangkok, Thailand,
        </em>{" "}
        2022; (DOI:{" "}
        <RenderLink
          href="http://dx.doi.org/10.1109/CloudCom55334.2022.00018"
          text="10.1109/CloudCom55334.2022.00018"
        />
        )
      </Typography>
      <Typography variant="h6" mb={3}>
        Sascha Martin Welten, Adrian Holt, Julian Hofmann, Lennart Schelter,
        Elena-Maria Klopries, Thomas Wintgens, Stefan Josef Decker; Synthetic
        rainfall data generator development through decentralised model
        training.
        <em>Journal of hydrology</em> 2022; (DOI:{" "}
        <RenderLink
          href="https://doi.org/10.1016/j.jhydrol.2022.128210"
          text="10.1016/j.jhydrol.2022.128210"
        />
        )
      </Typography>
      <Typography variant="h6" mb={3}>
        Sascha Martin Welten, Lars Hempel, Masoud Abedi, Yongli Mou, Mehrshad
        Jaberansary, Laurenz Neumann, Sven Weber, Kais Tahar, Yeliz Ucer Yediel,
        Matthias Löbe, Stefan Josef Decker, Oya Deniz Beyan, Toralf Kirsten;
        Multi-Institutional Breast Cancer Detection Using a Secure On-Boarding
        Service for Distributed Analytics. <em>Applied Sciences</em> 2022; (DOI:{" "}
        <RenderLink
          href="https://doi.org/10.3390/app12094336"
          text="10.3390/app12094336"
        />
        )
      </Typography>
      <Typography variant="h6" mb={7}>
        Sascha Welten, Yongli Mou, Laurenz Neumann, Mehrshad Jaberansary, Yeliz
        Ucer Yediel, Toralf Kirsten, Stefan Decker, Oya Beyan;
        Privacy-Preserving Distributed Analytics Platform for Health Care Data.{" "}
        <em>Methods of Information in Medicine</em> 2022; (DOI:{" "}
        <RenderLink
          href="https://doi.org/10.1055/s-0041-1740564"
          text="10.1055/s-0041-1740564"
        />
        )
      </Typography>
      <Typography variant="h5" fontWeight="bold" mb={3}>
        2021
      </Typography>
      <Typography variant="h6" mb={3}>
        Sascha Welten, Laurenz Neumann, Yeliz Ucer Yediel, Luiz Olavo Bonino da
        Silva Santos, Stefan Decker, Oya Beyan; DAMS: A Distributed Analytics
        Metadata Schema. <em>Data Intelligence</em> 2021; (DOI:{" "}
        <RenderLink
          href="https://doi.org/10.1162/dint_a_00100"
          text="10.1162/dint_a_00100"
        />
        )
      </Typography>
      <Typography variant="h6" mb={3}>
        Hochheiser H, Jing X, Garcia EA, Ayvaz S, Sahay R, Dumontier M, Banda
        JM, <b>Beyan O</b>, Brochhausen M, Draper E, Habiel S, Hassanzadeh O,
        Herrero-Zazo M, Hocum B, Horn J, LeBaron B, Malone DC, Nytrø Ø, Reese T,
        Romagnoli K, Schneider J, Zhang L(Y), Boyce RD.{" "}
        <RenderLink
          href="https://www.frontiersin.org/articles/10.3389/fphar.2020.608068/full"
          text="A Minimal Information Model for Potential Drug-Drug Interactions"
        />
        . <em>Front. Pharmacol</em>. (2021), 11:608068. doi:
        10.3389/fphar.2020.608068.
      </Typography>
      <Typography variant="h6" mb={7}>
        Karim MdR, Jiao J, Döhmen T, Cochez M, <b>Beyan O</b>, Rebholz-Schuhmann
        D, Decker S.{" "}
        <RenderLink
          href="https://ieeexplore.ieee.org/document/9363889"
          text="DeepKneeExplainer: Explainable Knee Osteoarthritis Diagnosis from Radiographs and Magnetic Resonance Imaging"
        />
        . (2021) <em>IEEE Access</em>. DOI: 10.1109/ACCESS.2021.3062493.
      </Typography>
      <Typography variant="h5" fontWeight="bold" mb={3}>
        2020
      </Typography>
      <Typography variant="h6" mb={3}>
        Gleim LC, Karim MdR, Zimmermann L, Kohlbacher O, Stenzhorn H, Decker S,{" "}
        <b>Beyan O</b>.{" "}
        <RenderLink
          href="https://jbiomedsem.biomedcentral.com/articles/10.1186/s13326-020-00223-z"
          text="Enabling Ad-hoc Reuse of Private Data Repositories through Schema Extraction"
        />
        . <em>Journal of Biomedical Semantics</em>. 11, 6 (2020).
        https://doi.org/10.1186/s13326-020-00223-z.
      </Typography>
      <Typography variant="h6" mb={3}>
        Karim MdR, Cochez M, Zappa A, Sahay R, Rebholz-Schuhmann D,{" "}
        <b>Beyan O</b>, Decker S.{" "}
        <RenderLink
          href="https://europepmc.org/article/med/32750845"
          text="Convolutional Embedded Networks for Population Scale Clustering and Bio-ancestry Inferencing"
        />
        .{" "}
        <em>
          IEEE/ACM Transactions on Computational Biology and Bioinformatics
        </em>
        . 2020 May;PP. DOI: 10.1109/tcbb.2020.2994649.
      </Typography>
      <Typography variant="h6" mb={3}>
        Bukowski M, Farkas R, <b>Beyan O</b>, Moll L, Hahn H, Kiessling F,
        Schmitz-Rode T.{" "}
        <RenderLink
          href="https://link.springer.com/article/10.1007%2Fs00330-020-06874-x"
          text="Implementation of eHealth and AI integrated diagnostics with multidisciplinary digitized data: are we ready from an international perspective?"
        />{" "}
        <em>European Radiology</em> (2020).
        https://doi.org/10.1007/s00330-020-06874-x.
      </Typography>
      <Typography variant="h6" mb={3}>
        Karim MdR, <b>Beyan O</b>, Zappa A, Costa IG, Rebholz-Schuhmann D,
        Cochez M, Decker S.{" "}
        <RenderLink
          href="https://academic.oup.com/bib/advance-article/doi/10.1093/bib/bbz170/5721075"
          text="Deep Learning-based Clustering Approaches for Bioinformatics"
        />
        . <em>Briefings in Bioinformatics</em>. bbz170. Published: 02 February
        2020. doi: 10.1093/bib/bbz170.
      </Typography>
      <Typography variant="h6" mb={3}>
        McQuilton P, Batista D, <b>Beyan O</b>, Granell R, Coles S, Izzo M,
        Lister AL, Pergl R, Rocca-Serra P, Schaap B, Shanahan H, Thurston M,
        Sansone SA.{" "}
        <RenderLink
          href="http://www.data-intelligence-journal.org/p/46/"
          text="Helping the consumers and producers of standards, repositories and policies to enable FAIR data"
        />
        . <em>Data Intelligence</em>. 2 (2020), 151-157. doi:
        10.1162/dint_a_00037.
      </Typography>
      <Typography variant="h6" mb={5}>
        <b>Beyan O</b>, Choudhury A, van Soest J, Kohlbacher O, Zimmermann L,
        Stenzhorn H, Karim MdR, Dumontier M, Decker S, Bonino da Silva Santos
        LO, Dekker A.{" "}
        <RenderLink
          href="http://www.data-intelligence-journal.org/p/39/"
          text="Distributed Analytics on Sensitive Medical Data: The Personal Health Train"
        />
        . <em>Data Intelligence</em>. 2 (2020), 96-107. doi:
        10.1162/dint_a_00032.
      </Typography>
    </>
  );

  const ConferenceFragment = () => (
    <>
      <Typography variant="h4" fontWeight="bold" mt={10} mb={5}>
        Conferences
      </Typography>
      <Typography variant="h5" fontWeight="bold" mb={3}>
        2022
      </Typography>
      <Typography variant="h6" mb={7}>
        Yongli Mou, Jiahui Geng, Sascha Martin Welten, Chunming Rong, Stefan
        Josef Decker, Oya Deniz Beyan; Optimized Federated Learning on
        Class-Biased Distributed Data Sources.{" "}
        <em>
          Machine Learning and Principles and Practice of Knowledge Discovery in
          Databases
        </em>{" "}
        2022; (DOI:{" "}
        <RenderLink
          href="https://doi.org/10.1007/978-3-030-93736-2_13"
          text="978-3-030-93736-2_13"
        />
        )
      </Typography>
      <Typography variant="h5" fontWeight="bold" mb={3}>
        2021
      </Typography>
      <Typography variant="h6" mb={7}>
        Yongli Mou, Sascha Welten, Mehrshad Jaberansary, Yeliz Ucer Yediel,
        Toralf Kirsten, Stefan Decker, Oya Beyan: Distributed Skin Lesion
        Analysis across Decentralised Data Sources.{" "}
        <RenderLink
          href="https://efmi.org/2020/12/10/31st-medical-informatics-europe-conference-mie2021-athens-greece/"
          text="31st Medical Informatics Europe Conference"
        />
        . 2021 (DOI: 10.3233/SHTI210179)
      </Typography>
      <Typography variant="h5" fontWeight="bold" mb={3}>
        2019
      </Typography>
      <Typography variant="h6" mb={3}>
        Karim MdR, Cochez M, <b>Beyan O</b>, Decker S, Lange C.
        OncoNetExplainer: Explainable Predictions of Cancer Types Based on Gene
        Expression Data.{" "}
        <RenderLink
          href="https://bibe2019.ics.forth.gr/"
          text="The 9th IEEE International Conference on Bioinformatics and Bioengineering (IEEE BIBE 2019)"
        />
        . Athens, Greece, October 28-30, 2019.
      </Typography>
      <Typography variant="h6" mb={3}>
        Fluck J, Pigeot I, Lindstädt B, Gübitz T, Zeeb H, Ahrens W, Löffler M,
        Brosteanu O, Lang U, Curdt C, Semler SC, <b>Beyan O</b>, Neuhausen H,
        Dierkes J, Sax U, Kusch H, Senst H, Muth T, Thun S, Kaiser D, Dress J,
        Müller W, Golebiewski M.{" "}
        <RenderLink
          href="https://www.egms.de/static/en/meetings/gmds2019/19gmds170.shtml"
          text="NFDI4Health: Ein Konzept für eine föderierte Forschungsdateninfrastruktur personenbezogener Gesundheitsdaten."
        />{" "}
        <em>
          64. Jahrestagung der Deutschen Gesellschaft für Medizinische
          Informatik, Biometrie und Epidemiologie e. V. (GMDS) Deutsche
          Gesellschaft für Medizinische Informatik, Biometrie und Epidemiologie
        </em>
        . Dortmund, September 08-11, 2019.
      </Typography>
      <Typography variant="h6" mb={3}>
        Karim MdR, Cochez M, Jares JB, Uddin M, <b>Beyan O</b>, Decker S.{" "}
        <RenderLink
          href="https://dl.acm.org/citation.cfm?id=3342161"
          text="Drug-Drug Interaction Prediction Based on Knowledge Graph Embeddings and Convolutional-LSTM Network"
        />
        .{" "}
        <em>
          The 10th ACM Conference on Bioinformatics, Computational Biology, and
          Health Informatics (ACM BCB 2019)
        </em>
        . Niagara Falls, New York, September 7-10, 2019.
      </Typography>
    </>
  );

  const DataUseCaseFragment = () => (
    <>
      <Typography variant="h4" fontWeight="bold" mt={10} mb={5}>
        Data Use Cases
      </Typography>
      <Typography variant="h5" fontWeight="bold" mb={3}>
        Distributed Skin Lesion Analysis across Decentralized Data Sources
      </Typography>
      <Typography variant="h6" mb={7}>
        We applied our architecture to Distributed Skin Lesion Analysis across
        Three Decentralised Data Sources. We performed a straightforward
        statistics study but also a heavy-weight Machine Learning task, where we
        trained a Classifier decentrally. More information can be found here:{" "}
        <RenderLink
          href="https://www.overleaf.com/project/5fe1ca00a90e106a80a252ce"
          text="https://www.overleaf.com/project/5fe1ca00a90e106a80a252ce"
        />
      </Typography>
      <Typography variant="h5" fontWeight="bold" mb={3}>
        Diagnosen-Koinzidenz für Mukoviszidose und Entbindung
      </Typography>
      <Typography variant="h6" mb={7}>
        We applied our architecture to a analysis about diagnose-coincidence for
        mucoviscidosis and childbirth. This data use case was under the umbrella
        of our CORD project, where we apply our distributed analytics
        infrastructure. More inforamtion can be found here:
      </Typography>
    </>
  );

  return (
    <Container>
      <ScrollToTop />
      <Title text="Publications" mb={5} />
      <JournalsFragment />
      <ConferenceFragment />
      <DataUseCaseFragment />
    </Container>
  );
};

export default PublicationsPage;
